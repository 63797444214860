<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId=='createContact'"
      :class="modalState?'show':''"
      tabindex="-1"
      role="dialog"
      style="display: block;"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Contact</h5>
            <a class="close" @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="addContact"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-12 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Contact Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Name</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.name.$error }">
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Full Name"
                              @blur="$v.formData.name.$touch()"
                              v-model.trim="formData.name"
                              required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.name.$error">
                            <p
                              class="error_level"
                              v-if="!$v.formData.name.required"
                            >Name field is required.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.name.minLength"
                            >At least 2 character name.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Email</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.email.$error }">
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Email"
                              @blur="$v.formData.email.$touch()"
                              v-model.trim.number="formData.email"
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.email.$error">
                            <p
                              class="error_level"
                              v-if="!$v.formData.email.minLength"
                            >At least 6 character email.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.email.maxLength"
                            >Max 255 character email.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Address</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.address.$error }">
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Address"
                              @blur="$v.formData.address.$touch()"
                              v-model.trim.number="formData.address"
                              required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.address.$error">
                            <p
                              class="error_level"
                              v-if="!$v.formData.address.required"
                            >Address field is required.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.address.minLength"
                            >At least 2 character address.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.address.maxLength"
                            >Max 255 character address.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Contact</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.contact.$error }">
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Contact"
                              @blur="$v.formData.contact.$touch()"
                              v-model.trim.number="formData.contact"
                              required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.contact.$error">
                            <p
                              class="error_level"
                              v-if="!$v.formData.contact.required"
                            >Contact field is required.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.contact.minLength"
                            >At least 7 character contact.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.contact.maxLength"
                            >Max 15 character contact.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.contact.numeric"
                            >Please enter numeric value.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Alternative Contact</label>
                          </div>
                          <div
                            class="col-md-6"
                            :class="{ invalid: $v.formData.alternative_contact.$error }"
                          >
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Alternative Contact"
                              @blur="$v.formData.alternative_contact.$touch()"
                              v-model.trim.number="formData.alternative_contact"
                              required
                            />
                          </div>
                          <div
                            class="col-md-12 text-right"
                            v-if="$v.formData.alternative_contact.$error"
                          >
                            <p
                              class="error_level"
                              v-if="!$v.formData.alternative_contact.minLength"
                            >At least 7 character alternative contact.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.alternative_contact.maxLength"
                            >Max 255 character alternative contact.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.contact.numeric"
                            >Please enter numeric value.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Location</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.location.$error }">
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Location"
                              @blur="$v.formData.location.$touch()"
                              v-model.trim.number="formData.location"
                              required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.location.$error">
                            <p
                              class="error_level"
                              v-if="!$v.formData.location.minLength"
                            >At least 2 character location.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.location.maxLength"
                            >Max 255 character location.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Branch</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.branch.$error }">
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Branch"
                              @blur="$v.formData.branch.$touch()"
                              v-model.trim.number="formData.branch"
                              required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.branch.$error">
                            <p
                              class="error_level"
                              v-if="!$v.formData.branch.minLength"
                            >At least 2 character branch.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.branch.maxLength"
                            >Max 255 character branch.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Featured</label>
                          </div>
                          <div class="col-md-6">
                            <input
                              type="radio"
                              name="featured"
                              id="featureYes"
                              value="1"
                              checked
                              v-model="formData.featured"
                            />
                            <label for="featureYes">
                              <span class="ml-2">Yes</span>
                            </label>
                            <input
                              type="radio"
                              class="ml-4"
                              name="featured"
                              id="featureNo"
                              value="0"
                              v-model="formData.featured"
                            />
                            <label for="featureNo">
                              <span class="ml-2">No</span>
                            </label>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Status</label>
                          </div>
                          <div class="col-md-6">
                            <input
                              type="radio"
                              name="status"
                              id="activateContact"
                              value="1"
                              checked
                              v-model="formData.status"
                            />
                            <label for="activateContact">
                              <span class="ml-2">Activate</span>
                            </label>
                            <input
                              type="radio"
                              class="ml-4"
                              name="status"
                              id="deactivateContact"
                              value="0"
                              v-model="formData.status"
                            />
                            <label for="deactivateContact">
                              <span class="ml-2">Deactivate</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary">Submit</button>
                <p
                  :class="eventMessage.indexOf('success')>=0?'success_level':'error_level'"
                >{{eventMessage}}</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  maxLength,
  minLength,
  numeric,
  required,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  computed: {
    ...mapGetters([
      "apiRoot",
      "token",
      "processing",
      "fileLocation",
      "eventMessage",
      "dataLists",
      "modalId",
      "modalState",
    ]),
  },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        address: "",
        contact: "",
        alternative_contact: "",
        location: "",
        branch: "",
        status: 1,
        featured: 0,
      },
    };
  },
  validations: {
    formData: {
      name: { required, minLength: minLength(2), maxLength: maxLength(255) },
      email: {
        minLength: minLength(6),
        maxLength: maxLength(50),
      },
      address: { required, minLength: minLength(2), maxLength: maxLength(255) },
      contact: {
        required,
        numeric,
        minLength: minLength(7),
        maxLength: maxLength(15),
      },
      alternative_contact: {
        numeric,
        minLength: minLength(7),
        maxLength: maxLength(15),
      },
      location: {
        minLength: minLength(2),
        maxLength: maxLength(255),
      },
      branch: { minLength: minLength(2), maxLength: maxLength(255) },
    },
  },
  methods: {
    addContact() {
      if (!this.$v.formData.$invalid) {
        this.$store.commit("setApiUrl", "api/contacts");
        this.$store.commit("addData", this.formData);
      } else {
        this.$store.commit("setEventMessage", `Please fill required fields.`);
      }
    },
  },
  watch: {
    eventMessage(value) {
      if (value && value.indexOf("success") >= 0) {
        this.formData.name = "";
        this.formData.designation = "";
        this.formData.explanation = "";
      }
    },
  },
};
</script>